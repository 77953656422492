<script>
	import { onMount } from "svelte";
	import { user, dbHost, listenOnFirebaseKey } from "../database";
	import Create from "../components/Create.svelte";
	import Wait from "../components/Wait.svelte";

	let isHost;

	onMount(() => {
		listenOnFirebaseKey(dbHost, val => {
			if (val == user.id) isHost = true;
		});
	});
</script>

<div class="createScreen">
	{#if isHost}
		<Create />
	{:else}
		<Wait />
	{/if}
</div>

<style>
	.createScreen {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
