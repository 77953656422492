import firebase from "firebase/app";
import "firebase/database";
import { getGameSessionId, getParams } from "./utils";

const firebaseConfig = {
	apiKey: process.env.API_KEY,
	authDomain: process.env.AUTH_DOMAIN,
	databaseURL: process.env.DATABASE_URL,
	projectId: process.env.PROJECT_ID,
	storageBucket: process.env.STORAGE_BUCKET,
	messagingSenderId: process.env.MESSAGING_SENDER_ID,
	appId: process.env.APP_ID,
};

firebase.initializeApp(firebaseConfig);

if (process.env.EMULATE) {
	const firebaseEmulators = {
		database: {
			host: "localhost",
			port: 9000,
		},
		functions: {
			host: "localhost",
			port: 5001,
		},
	};
	console.log("Automatically connecting Firebase SDKs to running emulators:");
	Object.keys(firebaseEmulators).forEach(function (key) {
		console.log("\t" + key + ": http://" + firebaseEmulators[key].host + ":" + firebaseEmulators[key].port);
	});
	if (firebaseEmulators.database && typeof firebase.database === "function") {
		firebase.database().useEmulator(firebaseEmulators.database.host, firebaseEmulators.database.port);
	}
	if (firebaseEmulators.firestore && typeof firebase.firestore === "function") {
		firebase.firestore().useEmulator(firebaseEmulators.firestore.host, firebaseEmulators.firestore.port);
	}
	if (firebaseEmulators.functions && typeof firebase.functions === "function") {
		firebase.functions().useEmulator(firebaseEmulators.functions.host, firebaseEmulators.functions.port);
	}
	if (firebaseEmulators.auth && typeof firebase.auth === "function") {
		firebase.auth().useEmulator("http://" + firebaseEmulators.auth.host + ":" + firebaseEmulators.auth.port);
	}
} else {
	console.log(
		"To automatically connect the Firebase SDKs to running emulators, replace '/__/firebase/init.js' with '/__/firebase/init.js?useEmulator=true' in your index.html"
	);
}

let roundValue = 1;

function getRoundValue() {
	return roundValue;
}

export const dbRoot = firebase.database().ref("pollgames");
export const dbPolls = firebase.database().ref("polls");
export const dbGameSession = dbRoot.child(getGameSessionId());
export const dbUsers = dbGameSession.child("users");
export const dbHost = dbGameSession.child("host");

export const dbGameSessionRoundValue = dbGameSession.child("roundValue");
export const dbGameSessionRounds = dbGameSession.child("rounds");
export const dbGameSessionRound = () => dbGameSessionRounds.child(`${getRoundValue()}`);

export const dbQuestion = () => dbGameSessionRound().child("question");
export const dbAnswersPicked = () => dbGameSessionRound().child("answersPicked");
export const dbReactions = () => dbGameSessionRound().child("reactions");
export const dbPageKey = () => dbGameSessionRound().child("page");

export const dbUser = dbUsers.child(getParams("userId"));

export function listenOnFirebaseKey(firebaseKey, callback, notExitCallback) {
	Promise.resolve(roundTimeValuePromise).then(() => {
		firebaseKey.on("value", snap => {
			if (snap.exists()) {
				callback(snap.val());
			} else {
				notExitCallback && notExitCallback();
			}
		});
	});
}

const roundTimeValuePromise = new Promise(resolve => {
	dbGameSessionRoundValue.once("value").then(snap => {
		if (!snap.exists()) {
			roundValue = 1;
		} else {
			roundValue = snap.val();
		}
		resolve(0);
	});
});

dbGameSessionRoundValue.on("value", snap => {
	if (!snap.exists()) {
		dbGameSessionRoundValue.set(1);
		roundValue = 1;
		return;
	}
	roundValue = snap.val();
});

export const user = {
	id: getParams("userId"),
	userName: getParams("userName"),
	profilePicture: getParams("userProfilePicture"),
};

const connectedRef = firebase.database().ref(".info/connected");
connectedRef.on("value", snap => {
	if (snap.val() === true) {
		dbUser.update({
			online: true,
		});
		const isHost = getParams("isHost") === "true";
		if (isHost) {
			dbHost.get().then(snap => {
				if (!snap.val()) {
					dbHost.set(user.id);
				}
			});
		}
		dbUser.onDisconnect().update({
			online: firebase.database.ServerValue.TIMESTAMP,
		});
	}
});

dbUser.update(user);
