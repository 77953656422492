<script>
	import { onMount, afterUpdate, onDestroy } from "svelte";
	import { ProfilePicture } from "da-components";
	import { clickOutside } from "./helper";
	export let votersArray;

	let isHovered = false;
	let myElement;
	let bottom;
	let right;
	let getPosition = () => {
		bottom = myElement?.getBoundingClientRect().bottom;
		right = myElement?.getBoundingClientRect().right;
	};

	function mouseOver() {
		isHovered = true;
	}
	function mouseLeave() {
		isHovered = false;
	}

	afterUpdate(async () => {
		getPosition();
		window?.addEventListener("resize", getPosition);
	});
	onMount(async () => {
		getPosition();
		window?.addEventListener("resize", getPosition);
	});
	onDestroy(async () => {
		window?.removeEventListener("resize", getPosition);
	});
</script>

<div
	use:clickOutside
	on:click_outside={mouseLeave}
	on:click={mouseOver}
	on:focus
	style="cursor: pointer;"
	class="wrapper"
	bind:this={myElement}
>
	<slot />
</div>

{#if isHovered}
	<div style="top: {bottom - 4}px; left: {right + 12}px;" class="options">
		{#each votersArray as voter}
			<div class="container">
				<ProfilePicture userName={voter?.userName} src={voter.profilePicture} size="24" fontSize="12" />
				<div class="name">
					<span class="text">{voter.userName}</span>
				</div>
			</div>
		{/each}
	</div>
{/if}

<style>
	.wrapper {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;
	}
	.options {
		width: 200px;
		max-height: 200px;
		background: #ffffff;
		box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.25);
		border-radius: 8px;
		position: fixed;
		z-index: 15;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 16px 16px 8px 16px;
		overflow-y: auto;
		overflow-x: hidden;
		position: absolute;
		transform: translate(-100%, 10%);
	}
	.container {
		width: 100%;
		height: 24px;
		display: flex;
		justify-content: flex-start;
		margin-bottom: 8px;
	}
	.name {
		width: calc(100% - 44px);
		height: 24px;
		margin-left: 10px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 16px;
		color: #36474f;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	::-webkit-scrollbar {
		display: none;
	}
</style>
