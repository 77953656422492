<script>
	import { tweened } from "svelte/motion";
	import { cubicOut } from "svelte/easing";

	export let value;

	$: if (value) {
		progress.set(value / 100);
	}

	const progress = tweened(0, {
		duration: 400,
		easing: cubicOut,
	});
</script>

<div class="outerline">
	<div class="innerline" style="width: {$progress * 100}%;" />
</div>

<style>
	.outerline {
		width: 100%;
		height: 5px;
		background: #efe8fa;
		border-radius: 27px;
		margin: 10px;
	}
	.innerline {
		height: 100%;
		background: #6c44a8;
		border-radius: 27px;
	}

	@media only screen and (max-width: 780px) {
		.outerline {
			height: 5px;
			margin: 5px 10px;
		}
	}
</style>
