<script>
	import firebase from "firebase/app";
	import { user, dbAnswersPicked } from "../database";
	export let questionData;

	let disabled = true;
	let vote = undefined;
	let answer = undefined;
	let loading = false;

	let onClick = () => {
		loading = true;
		if (questionData.mode === "poll" && vote >= 0) {
			dbAnswersPicked().update({
				mode: "poll",
				[user.id]: vote,
			});
		}
		if (questionData.mode === "ques" && answer) {
			dbAnswersPicked().update({
				mode: "ques",
				[user.id]: {
					answer,
					timestamp: firebase.database.ServerValue.TIMESTAMP,
				},
			});
		}
	};
	let checkHandler = id => {
		vote = id;
	};

	$: if (questionData.mode === "ques" && answer !== undefined && answer.trim() !== "") disabled = false;
	else if (questionData.mode === "ques" && (answer == undefined || answer.trim() === "")) disabled = true;

	$: if (questionData.mode === "poll" && vote !== undefined && vote >= 0) disabled = false;
	else if (questionData.mode === "poll" && (vote == undefined || vote < 0)) disabled = true;
</script>

<div class="questionContainer">
	<span class="subHeading"
		><span style="color: #6C44A8;">{questionData.mode === "poll" ? "Poll" : "Question"} •</span>
		<span style="color: #999999;">Created by {questionData.created_by}</span></span
	>
	<span class="question">{questionData.question}</span>
</div>

<div class="optionsContainer">
	{#if questionData.mode === "poll"}
		<div class="options">
			{#each questionData.options as option}
				<div class="option" on:click={() => checkHandler(option.id)} class:selected={vote == option.id}>
					<div class="round" class:btnSelected={vote == option.id} />
					<span class="option-answer">{option.answer}</span>
				</div>
			{/each}
		</div>
	{:else if questionData.mode === "ques"}
		<div class="answerContainer">
			<textarea name="answer" class="answer" placeholder="Answer" required bind:value={answer} />
		</div>
	{/if}
	<div class="submit">
		<span class="warning">Your answer will be shown publicly in the session</span>
		<button {disabled} on:click={onClick}>
			{#if loading}
				<img src="/assets/svg/loading.svg" alt="loading icon" class="loading" />
			{:else}
				<span>Submit</span>
			{/if}
		</button>
	</div>
</div>

<style>
	.questionContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 24px;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;

		margin: 0 0 16px 0;
	}
	.subHeading {
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-transform: capitalize;
		color: #6c44a8;

		margin: 0 10px 0 0;
	}
	.question {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		color: #060d19;

		margin: 8px 0 0 0;
		word-wrap: anywhere;
		word-break: break-all;
		hyphens: auto;
	}
	.optionsContainer {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;
	}
	.options {
		width: 100%;
		height: min-content;
		max-height: calc(90vh - 300px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px 8px 0 0;
		overflow-y: auto;
		overflow-x: hidden;
		margin: 0 0 16px 0;
	}
	.option {
		width: 100%;
		max-height: max-content;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 12px 16px;

		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;

		margin: 0 0 10px 0;
		cursor: pointer;
	}

	.option:hover {
		border: 1px solid #6c44a8;
	}
	.selected {
		border: 2px solid #6c44a8;
	}
	.round {
		width: 24px;
		height: 24px;
		border: 1px solid #dcdcdc;
		margin: 0 16px 0 0;
		border-radius: 50%;
	}
	.option:hover .round {
		border-color: #6c44a8;
		cursor: pointer;
	}
	.option-answer {
		width: calc(100% - 40px);
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;

		color: rgba(0, 0, 0, 0.8);
		word-wrap: break-word;
	}
	.btnSelected {
		/* my best engineering hack XD */
		box-shadow: 0 0 0 5px #ffffff inset;
		background: #6c44a8;
	}

	.answerContainer {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;

		margin: 0 0 16px 0;
	}
	.answer {
		width: 100%;
		height: 169px;
		display: flex;
		padding: 16px;

		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;

		margin: 0 0 4px 0;
		resize: none;
	}
	.text-warning {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-align: right;

		align-self: flex-end;

		margin-right: 4px;

		color: #f5685c;
		display: none;
	}
	.text-warning-show {
		display: inline;
	}
	.submit {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px;
	}
	.warning {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 12px;
		line-height: 24px;
		color: #6c44a8;
		margin: 0 12px 0 0;
	}
	.submit button {
		width: 153px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #6c44a8;
		border-radius: 8px;
		margin: 0;
		cursor: pointer;
	}
	.submit button:disabled {
		opacity: 0.5;
		cursor: no-drop;
	}
	.submit button span {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;

		text-align: center;

		color: #ffffff;
	}
	::-webkit-scrollbar {
		width: 8px;
		height: 122px;
	}
	::-webkit-scrollbar-thumb {
		background: #6c44a8;
		border-radius: 15px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
		margin-right: 10px;
	}

	::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 10px;
	}

	::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 10px;
	}
	@media only screen and (max-width: 770px) {
		.questionContainer,
		.optionsContainer {
			padding: 16px;
		}
		.options {
			width: 100%;
			height: min-content;
			max-height: calc(90vh - 250px);
			overflow-y: auto;
			overflow-x: hidden;
			margin-bottom: 0;
		}
	}
	@media only screen and (max-width: 500px) {
		.options {
			max-height: calc(90vh - 250px);
		}
		.submit {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
		}
		.submit button {
			margin-top: 8px;
		}
		::-webkit-scrollbar {
			width: 4px;
		}
	}
</style>
