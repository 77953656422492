<script>
	import { fly } from "svelte/transition";
	import { ProfilePicture } from "da-components";
	import { otherUsersStore } from "../store";
	import ProfilePictures from "./ProfilePictures.svelte";
	import Progress from "./Progress.svelte";
	import LeftToVoteList from "./LeftToVoteList/index.svelte";
	import Export from "../icons/export.svelte";
	import Caution from "../icons/caution.svelte";
	import Close from "../icons/close.svelte";
	import { dbAnswersPicked, dbGameSessionRoundValue, dbReactions, user, listenOnFirebaseKey } from "../database";
	import { convertToCSV, downloadCSV, formatDate } from "../utils";
	import { onMount } from "svelte";
	
	export let questionData;
	export let resultState;

	let showTooltip = localStorage.getItem("da-poll-export-tooltip");
	let hide = false;
	let currState = 0;
	let profileCarousel = [];
	let xArea = 400;
	let onlineUsers, peopleyetToVote;
	let roundValue;
	let hideModal = true;
	let resultofPoll, resultofQues, ownVote, users, voters, totalScore, isCreator;
	let questionReactions;
	let numberOfAnswerReactions = 0;
	let uniqueAnswerReactions = [];
	const REACTION_EMOJIS = {
		"thumbsUp": "👍",
		"love": "❤️",
		"laugh": "😂",
		"shock": "😲",
		"cry": "😢",
		"handFold": "🙏",
		"smile": "😊",
		"grin": "😃"
	};

	$: if (questionData.mode === "poll") {
		({ resultofPoll, ownVote, users, voters, totalScore, isCreator } = resultState); // In case the syntax is confusing - https://stackoverflow.com/questions/32138513/how-to-destructure-an-object-to-an-already-defined-variable
	} else if (questionData.mode === "ques") {
		({ resultofQues, ownVote, users, voters, totalScore, isCreator } = resultState);
	}

	$: {
		onlineUsers = $otherUsersStore.filter(user => user.online === true);
		peopleyetToVote = onlineUsers.filter(user => !voters.includes(user.id));
	}

	let leftRotate = (arr, d, n) => {
		for (let i = 0; i < d; i++) leftRotatebyOne(arr, n);
		return arr;
	};

	let leftRotatebyOne = (arr, n) => {
		let i, temp;
		temp = arr[0];
		for (i = 0; i < n - 1; i++) arr[i] = arr[i + 1];
		arr[n - 1] = temp;
	};

	let hidingModal = () => {
		hideModal = true;
	};

	let showModal = () => {
		hideModal = false;
	};

	let changeRound = async () => {
		await dbGameSessionRoundValue.once("value", snap => {
			if (snap.exists()) {
				roundValue = snap.val();
			}
		});
		await dbGameSessionRoundValue.set(roundValue + 1);
	};

	$: if (resultofQues?.length > 1) {
		hide = false;
	} else {
		hide = true;
	}

	$: if (resultofQues && currState >= 0) {
		let length = resultofQues.length;
		let shiftBy = ((length / 2) | 0) + 1 + currState;
		profileCarousel = [...resultofQues];
		profileCarousel = leftRotate([...profileCarousel], shiftBy, length);
		if (length > 5) {
			let mid = Math.ceil(length / 2);
			profileCarousel = profileCarousel.slice(mid - 3, mid + 2);
		}
	}

	let moveState = direction => {
		if (direction === "next") {
			xArea = 400;
			if (currState < resultofQues.length - 1) {
				currState = currState + 1;
			} else {
				currState = 0;
			}
		}
		if (direction === "prev") {
			xArea = -400;
			if (currState > 0) {
				currState = currState - 1;
			} else {
				currState = resultofQues.length - 1;
			}
		}
	};

	const exportData = () => {
		let data;

		if (resultState.resultofPoll === undefined) {
			data = resultState.resultofQues.map((item, index) => {
				if (index === 0) {
					return { 
						question: questionData.question,
						created_by: questionData.created_by,
						answer: item.answer,
						answered_by: item.userName,
						timestamp: item.timestamp,
					};
				} else {
					return { 
						question: "",
						created_by: "",
						answer: item.answer,
						answered_by: item.userName,
						timestamp: item.timestamp,
					};

				}
			});
		} else {
			data = resultState.resultofPoll.map((item, ind) => {
				const votedUsers = item.votedBy.map((users) => {
					const userData = Object.values(resultState.users);
					const index = userData.findIndex(user => user.id === users);

					if (index !== -1) return userData[index].userName;
					else return "";
				})

				if (ind === 0) {
					return {
						question: questionData.question,
						created_by: questionData.created_by,
						options: item.answer,
						percentage: item.percentage,
						voted_by: votedUsers.join(" ; ")
					}
				} else {
					return {
						question: "",
						created_by: "",
						options: item.answer,
						percentage: item.percentage,
						voted_by: votedUsers.join(" ; ")
					};
				}
			});
		}

		const csvData = convertToCSV(data);
		downloadCSV(csvData, "dive_poll_" + formatDate(new Date()) + ".csv");
	}

	let clickHandler = id => {
		let pos = resultofQues.findIndex(ques => ques.userId === id);
		currState = pos;
	};

	const emojiClickHandler = async (emoji) => {
		if (questionReactions?.[resultofQues[currState].userId]?.[user.id] === emoji[0]) {
			await dbReactions().child(resultofQues[currState].userId).child(user.id).set(null);
		} else {
			await dbReactions().child(resultofQues[currState].userId).child(user.id).set(emoji[0]);
		}
	}

	$: {
		const currentAnswerReactions = questionReactions?.[resultofQues?.[currState]?.userId || 0];
		if (currentAnswerReactions) {
			numberOfAnswerReactions = Object.values(currentAnswerReactions).length;
			uniqueAnswerReactions = Array.from(new Set(Object.values(currentAnswerReactions)));
		}
	}

	onMount(async () => {
		await dbReactions().child("status").set(true);
		listenOnFirebaseKey(dbReactions(), val => questionReactions = val);
	})
	
</script>

<div class="questionContainer">
	<span class="subHeading"
		><span style="color: #6C44A8;">{questionData.mode === "poll" ? "Poll" : "Question"} •</span>
		<span style="color: #999999;">Created by {questionData.created_by}</span></span
	>
	<span class="question">{questionData.question}</span>
	<div class="round-info">
		<div class="infowrapper">
			<span class="info">{voters.length} of {voters.length + peopleyetToVote.length} responses received</span>
			{#if isCreator && voters.length < voters.length + peopleyetToVote.length}
				<LeftToVoteList {peopleyetToVote}>
					<img src="/assets/svg/drop.svg" alt="dropdown" class="infoDropDown" />
				</LeftToVoteList>
			{/if}
		</div>
		{#if isCreator}
			<div class="hostActions">
				<button on:click={exportData} class="exportBtn">
					<span class="exportText">Export</span>
					<Export />
				</button>
				{#if showTooltip !== "false"}
					<div class="tooltip">
						<span class="tooltipText">
							💡 You can now export your responses in .csv format.
						</span>
							<button class="tooltipBtn" on:click={() => {
								localStorage.setItem("da-poll-export-tooltip", "false");
								showTooltip = "false";
							}}
								><span class="tooltipBtnText">Got it</span></button
							>
					</div>
				{/if}
				<button class="newQuestionBtn" on:click={showModal}
					><span class="newQuestionBtnText">Start a New Question</span></button
				>
			</div>
		{/if}
	</div>
</div>

{#if questionData.mode === "poll"}
	<div class="optionsContainer">
		{#each resultofPoll as r (r.id)}
			<div class="pollResult" class:selected={ownVote == r.id}>
				<div class="details">
					<div class="leftSide">
						<div class="round" class:btnSelected={ownVote == r.id} />
						<span class="poll-answer">{r.answer}</span>
					</div>
					<div class="rightSide">
						<div class="voters"><ProfilePictures votedBy={r.votedBy} {users} /></div>
						<span class="poll-percentage">{r.score} Votes ({r.percentage}%)</span>
					</div>
				</div>
				<div class="linediv">
					<Progress value={r.percentage} />
				</div>
			</div>
		{/each}
	</div>
{:else if questionData.mode === "ques"}
	<div class="answerContainer">
		<div class="anime">
			{#key currState}
				<div class="animeOuter" in:fly={{ x: xArea, duration: 1500 }} out:fly={{ x: -xArea, duration: 1500 }}>
					<div class="answerHeader">
						<ProfilePicture
							userName={resultofQues[currState]?.userName}
							src={resultofQues[currState]?.profilePicture}
							size="36"
							fontSize="18"
						/>
						<span class="answerName">{resultofQues[currState]?.userName}</span>
					</div>
					<div class="answerContent" >
						{resultofQues[currState]?.answer}
						<div class="reactionHolder">
							{#each Object.entries(REACTION_EMOJIS) as emoji}
								<div class="emoji" on:click={() => emojiClickHandler(emoji)}>
									{#if questionReactions?.[resultofQues[currState].userId]?.[user.id] === emoji[0]}
										<div class="activeEmoji" />
									{/if}
									{emoji[1]}
								</div>
							{/each}
						</div>
					</div>
					{#if questionReactions?.[resultofQues[currState].userId] && numberOfAnswerReactions > 0}
						<div class="reactionCount">
							{#each uniqueAnswerReactions as emoji}
								<div class="emoji" style="font-size: 1.2rem;">
									{REACTION_EMOJIS[emoji]}
								</div>
							{/each}
							{#if numberOfAnswerReactions > 1}
								{numberOfAnswerReactions}
							{/if}
							<div class="overallReactions">
								{#each Object.entries(questionReactions?.[resultofQues[currState].userId]) as reaction}
									<div class="userReactionRow">
										<ProfilePicture src={users[reaction[0]].profilePicture} size="20" fontSize="12" />
										<div class="reactionUserName">{users[reaction[0]].userName.split("").length > 8 ? users[reaction[0]].userName.split("").slice(0,8).join("") + "..." : users[reaction[0]].userName}</div>
										<div>{REACTION_EMOJIS?.[reaction[1]] || ""}</div>
									</div>
								{/each}
							</div>
						</div>
					{/if}
				</div>
			{/key}
		</div>
		<div class="profileCarousel">
			{#each profileCarousel as item, i (item.userId)}
				<div
					class="carouselItem"
					class:opacity={i != Math.ceil(profileCarousel.length / 2 - 1)}
					on:click={() => clickHandler(item.userId)}
				>
					<ProfilePicture userName={item.userName} src={item.profilePicture} size="26" fontSize="14" />
				</div>
			{/each}
		</div>

		<img class:hide src="/assets/svg/left.svg" alt="left" class="left" on:click={() => moveState("prev")} />
		<img class:hide src="/assets/svg/right.svg" alt="right" class="right" on:click={() => moveState("next")} />
	</div>
{/if}

<div class="modaltoNextRound" class:hideModal>
	<div class="modal">
		<div class="closeModal" on:click={hidingModal}><Close /></div>
		<span class="text">
			<Caution />  
			<span>Caution</span>
		</span>
		<span class="warning"
			>Once you start a new question, you will loose all the answers of the current question. </span
		>
		<div class="buttons">
			<div class="nextButton" on:click={() => {
				exportData();
				changeRound();
			}}>Export & Proceed</div>
			<div class="cancelBtn" on:click={changeRound}>Proceed</div>
		</div>
	</div>
</div>

<style>

	
	@keyframes fadeIn {
		from { opacity: 0; }
		to { opacity: 1; }
	}

	.anime {
		width: 100%;
		display: grid;
		place-items: center;
	}
	.animeOuter {
		width: 100%;
		grid-row: 1;
		grid-column: 1;
		position: relative;
		background: #ffffff;
		border-radius: 8px;
		padding: 24px 24px 0 24px;
	}
	.questionContainer {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 24px;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;

		margin: 0 0 16px 0;
	}
	.reactionHolder {
		position: absolute;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;
		right: 0;
		top: 0;
		border-radius: 10rem 10rem;
		background-color: #cacaca;
		padding: 2%;
		box-shadow: 7px 7px 9px #a4a4a440;
		opacity: 0;
	}

	.answerContent:hover .reactionHolder {
		opacity: 1;
		animation: fadeIn 1s;
    	animation-fill-mode: forwards;
	}

	.overallReactions {
		position: absolute;
		display: none;
		flex-direction: column;
		font-size: 1rem;
		padding: 1rem 0.5rem;
		background: #fff;
		box-shadow: 3px 3px 12px #a4a4a440;
		border-radius: 8px;
		gap: 0.6rem;
		min-width: 14rem;
		max-height: 10rem;
		min-height: 5rem;
		overflow: auto;
		left: 0;
		transform: translate(-84%, 101%);
		bottom: 0;
	}


	.userReactionRow {
		display: inline-flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 1rem;
		flex-direction: row;
		justify-content: space-between; 
		align-content: center;
		padding: 0.1rem 0.5rem;
		width: 100%;
	}
	.reactionCount {
		position: absolute;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 0.5rem;
		top: 2.6rem;
		right: 5%;
		border-radius: 10rem 10rem;
		background-color: #cacaca;
		padding: 1%;
		box-shadow: 7px 7px 9px #a4a4a440;
		opacity: 0;
    	animation: fadeIn 1s;
    	animation-fill-mode: forwards;
		color: #6c44a8;
		font-weight: 600;
		justify-content: center;
		align-items: center;
	}

	.reactionCount:hover .overallReactions {
		display: flex;
	}

	.reactionUserName {
		width: 55%;
		text-align: left;
	}

	.emoji {
		font-size: 1.2rem;
		cursor: grab;
		text-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		opacity: 0;
    	animation: fadeIn 2s;
    	animation-fill-mode: forwards;
		position: relative;
	}

	.activeEmoji {
		background-color: #e8e8e8;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		padding: 74%;
		z-index: -100;
		border-radius: 10rem;
	}
	
	.closeModal {
		position: absolute;
		right: 15px;
		cursor: pointer;
	}
	.subHeading {
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-transform: capitalize;
		color: #6c44a8;

		margin: 0 10px 0 0;
	}
	.question {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		color: #060d19;

		margin: 8px 0 0 0;
		word-wrap: anywhere;
		word-break: break-all;
		hyphens: auto;
	}
	.round-info {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px;
		margin: 8px 0 0 0;
	}
	.infowrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
	}
	.info {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;

		color: #6c44a8;
		margin: 0 8px 0 0;
	}
	.infoDropDown {
		width: 13px;
		height: 13px;
		margin: 0 10px 0 0;
		cursor: pointer;
		margin-top: -1px;
	}
	.newQuestionBtn {
		width: 181px;
		height: 40px;
		border: 1px solid #6c44a8;
		box-sizing: border-box;
		border-radius: 12px;
		margin: 0;
		background: #ffffff;
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		color: #6c44a8;
	}

	.tooltipBtn {
		border: 1px solid #6c44a8;
		box-sizing: border-box;
		border-radius: 1rem;
		margin: 0;
		font-family: Manrope;
		font-style: normal;
		font-weight: 800;
		font-size: 14px;
		line-height: 19px;
		text-align: center;
		background: #6c44a8;
		color: #fff;
		cursor: pointer;
		width: 7rem;
	}

	.exportBtn {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		align-items: center;
		justify-content: center;
		margin: 0px 8px;
		font-family: 'Manrope';
		font-weight: 600;
		font-size: 16px;
		color: #6C44A8;
		line-height: 22px;
		border-radius: 1px 20px;
		background: #EFE8FA;
		backdrop-filter: blur(4px);
		border-radius: 52px;
		padding: 6px 9px;
		cursor: pointer;
	}

	.exportBtn:hover > .exportText {
		display: inline;
	}

	.hostActions {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 0.2rem;
		position: relative;
	}

	.exportText {
		display: none;
	}

	.tooltip {
		display: flex;
		position: absolute;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		padding: 1rem 1rem;
		background-color: #fff;
		border-radius: 1rem;
		box-shadow: 0px 4px 12px #00000040;
		width: 20rem;
		left: -16rem;
		top: 3rem;
		z-index: 2;
	}

	.tooltip:after {
		content: "";
		position: absolute;
		top: -10px;
		right: 25px;
		bottom: auto;
		left: auto;
		border-width: 0 10px 10px;
		border-style: solid;
		border-color: #fff #fff8f800;
		display: block;
		width: 0;
	}
	.tooltipText {
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 130%;
	}

	.newQuestionBtn:hover {
		background: #6c44a8;
		color: #ffffff;
		cursor: pointer;
	}
	.optionsContainer {
		width: 100%;
		height: min-content;
		max-height: calc(90vh - 200px);
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 24px;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;

		overflow-y: auto;
		overflow-x: hidden;
	}
	.pollResult {
		width: 100%;
		max-height: max-content;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
		padding: 16px;

		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;

		margin: 0 0 16px 0;
	}
	.details {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.leftSide,
	.rightSide {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0px;
		width: 50%;
		flex: none;
		order: 0;
	}
	.leftSide {
		justify-content: flex-start;
	}
	.rightSide {
		justify-content: flex-end;
	}
	.voters {
		width: 50%;
		height: 22px;
	}
	.linediv {
		width: 100%;
		display: flex;
		margin-top: 8px;
		padding: 0;
	}
	.round {
		width: 24px;
		height: 24px;
		border: 1px solid #dcdcdc;
		margin: 0px 10px;
		border-radius: 50%;
	}
	.poll-answer {
		width: calc(100% - 54px);
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;

		color: #060d19;

		margin: 0 10px 0 0;
		word-wrap: break-word;
	}
	.poll-percentage {
		width: 50%;
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 19px;

		color: #6c44a8;
		text-align: right;
		word-wrap: break-word;

		display: flex;
		justify-content: flex-end;
		margin: 0 0 0 10px;
	}
	.selected {
		border: 2px solid #6c44a8;
	}
	.btnSelected {
		/* my best engineering hack XD */
		box-shadow: 0 0 0 5px #ffffff inset;
		background: #6c44a8;
	}
	.answerContainer {
		width: 100%;
		height: auto;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		padding-bottom: 24px;
		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;
		position: relative;
	}
	.answerHeader {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		padding: 0px;
	}
	.answerName {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 22px;

		color: #6c44a8;

		margin: 0 0 0 8px;
	}
	.answerContent {
		width: 100%;
		height: 125px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		padding: 16px;

		background: #f8f8f8;
		border-radius: 8px;

		margin: 16px 0px;

		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 145%;

		letter-spacing: 0.01em;

		color: #060d19;
		word-break: break-word;
		white-space: pre-line;
		overflow: auto;
		position: relative;
	}

	.left,
	.right {
		position: absolute;
		top: 50%;
		cursor: pointer;
	}
	.left {
		left: -12px;
	}
	.right {
		right: -12px;
	}
	.hide {
		display: none;
	}
	.profileCarousel {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 0px;
	}
	.carouselItem {
		width: 26px;
		height: 26px;
		margin: 0 12px;
		cursor: pointer;
	}
	.opacity {
		opacity: 0.5;
	}

	.modaltoNextRound {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		position: fixed;
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 3;
	}
	.modal {
		width: 90%;
		max-width: 374px;
		max-height: 283px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		padding: 24px;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;
		position: relative;
	}
	.text {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 0.5rem;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		text-align: center;
		font-weight: 800;
		font-size: 26px;
		line-height: 24px;
		color: #060D19;

		margin: 16px 0px;
	}
	.warning {
		width: 100%;
		font-family: Manrope;
		font-style: normal;
		text-align: left;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #444444;
	}
	.buttons {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0px;
		margin: 16px 0 0 0;
	}
	.cancelBtn {
		width: 50%;
		max-width: 151px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px 20px;
		font-weight: 700;
		font-size: 0.8rem;
		background: #ffffff;
		color: #6c44a8;
		border-radius: 8px;
		cursor: pointer;
	}
	.nextButton {
		width: 50%;
		max-width: 151px;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		padding: 12px 20px;
		font-size: 0.8rem;
		background: #6c44a8;
		color: #ffffff;
		border-radius: 8px;
		cursor: pointer;
		font-weight: 700;
	}
	.hideModal {
		display: none;
	}
	::-webkit-scrollbar {
		width: 8px;
		height: 122px;
	}
	::-webkit-scrollbar-thumb {
		background: #6c44a8;
		border-radius: 15px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
		margin-right: 10px;
	}

	::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 10px;
	}

	::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 10px;
	}
	@media only screen and (max-width: 770px) {
		.questionContainer,
		.optionsContainer {
			padding: 16px;
		}
		.optionsContainer {
			width: 100%;
			height: min-content;
			max-height: calc(90vh - 150px);
			overflow-y: auto;
			overflow-x: hidden;
			margin-bottom: 0;
		}
	}
	@media only screen and (max-width: 500px) {
		.optionsContainer {
			max-height: calc(90vh - 250px);
		}
		.round-info {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
		}
		.newQuestionBtn {
			margin-top: 8px;
		}
		.answerContent {
			height: 200px;
		}
		::-webkit-scrollbar {
			width: 4px;
		}
	}
</style>
