<script>
	import { onDestroy, onMount } from "svelte";
	import { isEqual } from "../utils";
	import {
		dbGameSessionRounds,
		dbGameSessionRoundValue,
		dbHost,
		dbUsers,
		listenOnFirebaseKey,
		user,
	} from "../database";
	import { myUserStore, otherUsersStore, usersStore } from "../store";
	import VoteScreen from "./VoteScreen.svelte";
	import CreateScreen from "./CreateScreen.svelte";

	let page;
	let users = [];
	let hostId;
	let isHost;

	$: {
		if (user.id === hostId) {
			dbHost.onDisconnect().remove();
		}
	}

	listenOnFirebaseKey(dbHost, val => {
		hostId = val;
		if (hostId === user.id) isHost = true;
		if (hostId) {
			const oldHostName = users.find(user => user.id === hostId)?.userName;
			const newHostName = users.find(user => user.id === val)?.userName;
			let message = "";
			if (hostId === user.id) {
				message = `${oldHostName || "Old Host"} has left the game and you are the new host`;
			} else {
				if (newHostName) {
					message = `${oldHostName || "Old Host"} has left the game and new host is ${newHostName}`;
				} else {
					message = `${oldHostName || "Old Host"} has left the game and new host has been assigned`;
				}
			}
		}
	});

	function onPageChange(snap) {
		if (!snap.exists()) {
			page = "CREATE_POLL_SCREEN";
			return;
		}
		page = snap.val();
	}

	let roundValue;
	var dbPageKey;
	dbGameSessionRoundValue.on("value", snap => {
		if (!snap.exists()) {
			return;
		}
		roundValue = snap.val();
		if (dbPageKey) {
			dbPageKey.off("value", onPageChange);
		}
		dbPageKey = dbGameSessionRounds.child(roundValue).child("page");
		dbPageKey.on("value", onPageChange);
	});

	listenOnFirebaseKey(dbUsers, val => {
		usersStore.set(val);
		users = Object.values(val);
		myUserStore.set(users.find(u => u.id === user.id));
	});

	let timer;
	let oldOtherUserList = [];

	onMount(() => {
		timer = setInterval(() => {
			const allOtherUsers = users.filter(u => u.id !== user.id);
			const otherUserList = [];
			allOtherUsers.forEach(user => {
				if (user.online === true) {
					otherUserList.push({ ...user, online: true });
				} else if (typeof user.online === "number") {
					if (Date.now() - user.online > 5000) {
						otherUserList.push({ ...user, online: false });
					} else {
						otherUserList.push({ ...user, online: true });
					}
				} else {
					otherUserList.push({ ...user, online: false });
				}
			});
			if (!isEqual(otherUserList, oldOtherUserList)) {
				otherUsersStore.set(otherUserList);
				oldOtherUserList = otherUserList;
			}
		}, 1000);
	});
	onDestroy(() => {
		clearInterval(timer);
	});
</script>

<div class="image" />

<div class="poll">
	{#if page == "CREATE_POLL_SCREEN"}
		<CreateScreen />
	{:else if page == "VOTE_SCREEN"}
		<VoteScreen />
	{/if}
</div>

<style>
	:global(*) {
		box-sizing: border-box;
	}
	.poll {
		width: 100%;
		height: 100%;
		font-family: Manrope;
		position: absolute;
		z-index: 10;
		overflow-x: hidden;
	}
	.image {
		position: absolute;
		filter: blur(150px);
		-webkit-filter: blur(150px);
		background-image: url("/assets/svg/bg.svg");
		width: 100%;
		height: 100%;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
	}
</style>
