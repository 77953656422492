<script>
	import { ProfilePicture } from "da-components";
	import VotersList from "./VotersList/index.svelte";
	export let votedBy;
	export let users;
	let innerWidthsize;
	let maxAvaters;
	let votersArray = [];
	let plusVoters = 0;

	$: {
		if (innerWidthsize && innerWidthsize > 770) maxAvaters = 4;
		else maxAvaters = 2;
	}

	$: {
		if (votedBy.length > 0 && users) {
			let arr = [];
			votedBy.forEach(vote => {
				if (users[vote]) {
					let voter = {
						userId: users[vote].id,
						userName: users[vote].userName,
						profilePicture: users[vote].profilePicture,
					};
					arr = [...arr, voter];
				}
			});
			votersArray = [...arr];
		}
	}

	$: {
		if (votersArray.length > maxAvaters) {
			plusVoters = votersArray.length - maxAvaters;
		} else {
			plusVoters = 0;
		}
	}
</script>

<svelte:window bind:innerWidth={innerWidthsize} />

{#if votersArray && votersArray.length > 0}
	<VotersList {votersArray}>
		<div class="avatars">
			{#if plusVoters <= 0}
				{#each votersArray as voter}
					<div class="pictureWrapper">
						<ProfilePicture userName={voter?.userName} src={voter.profilePicture} size="24" fontSize="12" />
					</div>
				{/each}
			{:else}
				{#each { length: maxAvaters } as _, i}
					<ProfilePicture
						userName={votersArray[i]?.userName}
						src={votersArray[i].profilePicture}
						size="24"
						fontSize="12"
					/>
				{/each}
				<div class="plusVoters">+{plusVoters}</div>
			{/if}
		</div>
	</VotersList>
{/if}

<style>
	.avatars {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.plusVoters {
		width: 24px;
		height: 24px;
		font-size: 0.75em;
		color: white;
		font-weight: 700;
		display: flex;
		font-family: "Manrope", sans-serif;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		border: 1px solid #ffffff;
		box-sizing: border-box;
		background-color: #6c44a8;
		object-fit: cover;
		margin-left: -4px;
	}
	.pictureWrapper {
		width: 24px;
		height: 24px;
		margin-left: -4px;
	}
</style>
