<script>
	import { afterUpdate } from "svelte";
	import { user, dbPageKey, dbQuestion } from "../database";
	import { getParams } from "../utils";

	let disabled = true;
	let mode = "poll";
	let question = "";
	let loading = false;

	let pollOptions = [{ id: 0, answer: "" }];
	let lastId = 0;

	let returnsCurrentLayout = () => {
		let optionDiv = [...document.querySelectorAll(".option")];
		let pos = [];
		optionDiv.forEach(div => {
			pos = [...pos, Number(div.id)];
		});
		return pos;
	};

	let onSubmit = async () => {
		loading = true;
		if (mode === "poll") {
			let cleanedOptions = pollOptions.filter(option => option.answer !== "");
			let layout = returnsCurrentLayout(cleanedOptions);
			let optionstoSubmit = [];
			layout.forEach(item => {
				cleanedOptions.forEach(e => {
					if (item === e.id) {
						optionstoSubmit = [...optionstoSubmit, e];
					}
				});
			});

			let questionData = {
				mode: "poll",
				created_by: user.userName,
				created_by_id: user.id,
				question: question,
				options: optionstoSubmit,
			};
			await dbQuestion().set(questionData);
			window?.DIVE_APP?.sendEvent({
				type: "mode_change",
				payload: {
					mode: "mcq",
				},
			});

			if (!!getParams("publishMode")) {
				window?.DIVE_APP.sendEvent({
					type: "sidebar_app",
					payload: {
						questionData: questionData,
					}
				})
			 }
			await dbPageKey().set("VOTE_SCREEN");
		}

		if (mode === "ques") {
			let questionData = {
				mode: "ques",
				created_by: user.userName,
				created_by_id: user.id,
				question: question,
			};
			await dbQuestion().set(questionData);
			window?.DIVE_APP?.sendEvent({
				type: "mode_change",
				payload: {
					mode: "ques",
				},
			});

			if (!!getParams("publishMode")) {
				window?.DIVE_APP.sendEvent({
					type: "sidebar_app",
					payload: {
						questionData: questionData,
					}
				})
			}
			await dbPageKey().set("VOTE_SCREEN");
		}
	};

	let addOption = () => {
		lastId++;
		pollOptions = [...pollOptions, { id: lastId, answer: "" }];
	};

	let deleteOption = id => {
		let layout = returnsCurrentLayout();
		let filteredOptions = [];
		layout.forEach(p => {
			pollOptions.forEach(e => {
				if (p === e.id && e.id !== id) {
					filteredOptions = [...filteredOptions, e];
				}
			});
		});
		pollOptions = filteredOptions;
	};

	let trackInputinOption = id => {
		let element = document.getElementById(id);
		if (element.value.length === 30) {
			element.classList.add("borderChange");
		} else {
			element.classList.remove("borderChange");
		}
	};

	$: if (mode === "ques" && question !== undefined && question !== "") {
		disabled = false;
	} else if (mode === "ques" && (question == undefined || question == "")) {
		disabled = true;
	}

	$: {
		let validOptions = pollOptions.filter(option => option.answer !== "");
		if (mode === "poll" && validOptions.length >= 2 && question !== undefined && question !== "") disabled = false;
		else if (mode === "poll" && (validOptions.length < 2 || question == undefined || question == ""))
			disabled = true;
	}

	afterUpdate(() => {
		const draggables = document.querySelectorAll(".option");
		const containers = document.querySelectorAll(".options");

		draggables.forEach(draggable => {
			draggable.addEventListener("dragstart", () => {
				draggable.classList.add("dragging");
			});

			draggable.addEventListener("dragend", () => {
				draggable.classList.remove("dragging");
			});
		});

		containers.forEach(container => {
			container.addEventListener("dragover", e => {
				e.preventDefault();
				const afterElement = getDragAfterElement(container, e.clientY);
				const draggable = document.querySelector(".dragging");
				if (afterElement == null) {
					container.appendChild(draggable);
				} else {
					container.insertBefore(draggable, afterElement);
				}
			});
		});

		function getDragAfterElement(container, y) {
			const draggableElements = [...container.querySelectorAll(".option:not(.dragging)")];

			return draggableElements.reduce(
				(closest, child) => {
					const box = child.getBoundingClientRect();
					const offset = y - box.top - box.height / 2;
					if (offset < 0 && offset > closest.offset) {
						return { offset: offset, element: child };
					} else {
						return closest;
					}
				},
				{ offset: Number.NEGATIVE_INFINITY }
			).element;
		}
	});
</script>

<div class="container">
	<div class="createPollGame">
		<div class="heading">
			<span class="heading-text">{!!getParams("publishMode") ? "What do you want to ask?" : "Ask your Question"} </span>
		</div>

		<div class="body-container">
			<div class="questionArea">
				<div class="quesWraper">
					<textarea
						name="question"
						class="question-ques"
						placeholder="Question"
						required
						maxlength="500"
						bind:value={question}
					/>
					<span class="text-warning" class:text-warning-show={question?.length > 450}
						>{question?.length} / 500</span
					>
				</div>
			</div>

			{#if mode === "poll"}
				<div class="options">
					{#each pollOptions as option, i (option.id)}
						<div class="option" draggable="true" id={pollOptions[i].id}>
							<div class="svgDrag"><img src="/assets/svg/drag.svg" alt="drag icon" /></div>
							<input
								name="answer"
								type="text"
								class="answer"
								id={`option-${i}`}
								placeholder="Enter option"
								maxlength="30"
								bind:value={pollOptions[i].answer}
								on:input={() => trackInputinOption(`option-${i}`)}
							/>
							{#if pollOptions.length > 1}
								<div class="svgtrash" on:click={() => deleteOption(option.id)}>
									<img src="/assets/svg/trash.svg" alt="trash icon" />
								</div>
							{/if}
						</div>
					{/each}
				</div>

				<div class="add-options" on:click={addOption}>
					<img src="/assets/svg/add.svg" alt="add icon" class="add-options-svg" />
					<span class="add-options-text">Add another option</span>
				</div>
			{/if}
		</div>
		<div class="footer">
			<div class="information">
				<span class="info-0"> Only one question allowed* </span>
				<span class="info-2">Your answer will be shown publicly in the session</span>
			</div>
			<button {disabled} on:click={onSubmit} class="button">
				{#if loading}
					<img src="/assets/svg/loading.svg" alt="loading icon" class="loading" />
				{:else}
					<span class="btn-text">Publish</span>
				{/if}
			</button>
		</div>
	</div>
</div>

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.createPollGame {
		width: calc(100% - 32px);
		height: auto;
		max-width: 560px;
		max-height: 90%;

		margin-left: auto;
		margin-right: auto;
		padding: 24px;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		background: #ffffff;
		box-shadow: 3px 3px 12px rgba(164, 164, 164, 0.25);
		border-radius: 8px;
	}

	.heading {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 16px;
	}
	.heading-text {
		font-family: Manrope;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 27px;
		text-align: center;
		color: #6c44a8;
	}

	.body-container {
		width: 100%;
		height: calc(100% - 90px);
		display: flex;
		flex-direction: column;
	}

	.questionArea {
		width: 100%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
	}
	.quesWraper {
		width: 100%;
		height: auto;
		margin: 0 12px 0 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
	}
	.question-ques {
		width: 100%;
		height: 90px;

		padding: 12px 16px;
		margin: 0 0 4px 0;

		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;

		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 22px;

		resize: none;
	}
	.question-ques:hover {
		border: 1px solid #6c44a8;
	}
	.text-warning {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		text-align: right;

		align-self: flex-end;

		margin-right: 4px;

		color: #f5685c;
		display: none;
	}
	.text-warning-show {
		display: inline;
	}
	.info-0 {
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: rgba(0, 0, 0, 0.4);
	}
	.info-2 {
		font-weight: bold;
	}
	.options {
		width: 100%;
		height: min-content;
		max-height: calc(90vh - 300px);
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0px;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.option {
		width: 100%;
		height: 44px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px;
		margin: 2px 0px;
		padding-right: 2px;
	}
	.svgDrag {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 8px 0 0;
		pointer-events: none;
	}
	.answer {
		width: inherit;
		height: 100%;
		background: #ffffff;
		border: 1px solid #dcdcdc;
		box-sizing: border-box;
		border-radius: 8px;
		margin: 0;
	}
	:global(.borderChange:focus) {
		outline: none !important;
		border: solid 2px #f5685c !important;
	}
	.svgtrash {
		width: 24px;
		height: 24px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 8px;
		cursor: pointer;
	}

	.add-options {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin: 12px 0px;
		cursor: pointer;
	}

	.add-options-svg {
		width: 20px;
		height: 20px;
		margin: 0 10px 0 0;
	}

	.add-options-text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 500;
		font-size: 13px;
		line-height: 18px;
		color: #6c44a8;
	}

	.footer {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 0px;

		margin: 0px;
	}
	.information {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		font-family: Manrope;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 24px;
		color: #6c44a8;

		margin: 0 10px 0 0;
	}
	.button {
		width: 153px;
		height: 48px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #6c44a8;
		border-radius: 8px;
		margin: 0;
		cursor: pointer;
	}
	.button:disabled {
		/* Using rgba instead of opacity as opacity was somehow bleeding into the mode picker modal */
		background: rgba(108, 68, 168, 0.5);
		cursor: no-drop;
	}
	.btn-text {
		font-family: Manrope;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		color: #ffffff;
	}
	::-webkit-scrollbar {
		width: 8px;
		height: 122px;
	}
	::-webkit-scrollbar-thumb {
		background: #6c44a8;
		border-radius: 15px;
	}
	::-webkit-scrollbar-track {
		background: transparent;
		margin-left: 10px;
	}

	::-webkit-scrollbar-track-piece:end {
		background: transparent;
		margin-bottom: 10px;
	}

	::-webkit-scrollbar-track-piece:start {
		background: transparent;
		margin-top: 10px;
	}
	::placeholder {
		color: rgba(0, 0, 0, 0.4);
		opacity: 1;
	}
	@media only screen and (max-width: 500px) {
		.heading-text {
			font-size: 16px;
		}
		.createPollGame {
			padding: 18px;
			width: 100%;
			height: 100%;
			max-height: 100%;
		}
		.question-ques {
			margin: 2px 0 4px 0;
		}
		.questionArea {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			margin-bottom: 0px;
		}
		.quesWraper {
			width: 100%;
			max-width: 100%;
			margin: 0;
			order: 2;
		}
		.options {
			width: 100%;
			height: min-content;
			max-height: calc(90vh - 400px);
			overflow-y: auto;
			overflow-x: hidden;
		}
		.footer {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
		}
		::-webkit-scrollbar {
			width: 4px;
		}
	}
</style>
