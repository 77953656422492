import * as Sentry from "@sentry/browser";
import "@sentry/tracing";
import App from "./App.svelte";
if (process.env.SENTRY_ENVIRONMENT === "prod" || process.env.SENTRY_ENVIRONMENT === "dev") {
    Sentry.init({
        dsn: "https://4dca10f496c249e486abacbb8cb08c29@o422923.ingest.sentry.io/6059980",
        environment: process.env.SENTRY_ENVIRONMENT,
        tracesSampleRate: 1.0,
    });
}
const app = new App({
    target: document.body,
});
export default app;
