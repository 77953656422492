<script>
	import { onMount } from "svelte";
	import {
		user,
		dbUsers,
		dbAnswersPicked,
		dbQuestion,
		listenOnFirebaseKey,
		dbGameSessionRoundValue,
	} from "../database";
	import { usersStore } from "../store";
	import Result from "../components/Result.svelte";
	import Vote from "../components/Vote.svelte";
	
	let resultofPoll;
	let resultofQues;
	let ownVote;
	let totalScore;
	let users;
	let voters;

	let questionData;
	let isCreator;
	let hasVoted = false;

	let resultState;
	let roundValue;
	let hideModal = true;

	let hidingModal = () => {
		hideModal = true;
	};

	let showModal = () => {
		hideModal = false;
	};

	let changeRound = async () => {
		await dbGameSessionRoundValue.once("value", snap => {
			if (snap.exists()) {
				roundValue = snap.val();
			}
		});
		await dbGameSessionRoundValue.set(roundValue + 1);
	};

	const calculateResultforPoll = val => {
		let tempScore = {};
		let count = 0;
		for (const [key, value] of Object.entries(val)) {
			if (key === "mode") {
				continue;
			}
			if (user.id == key) ownVote = value;
			if (tempScore[value]) {
				tempScore[value].score++;
				tempScore[value].votedBy = [...tempScore[value].votedBy, key];
			} else {
				tempScore[value] = { id: value, score: 1, votedBy: [key] };
			}
			count++;
		}
		totalScore = count;
		return questionData.options.map(option => {
			if (tempScore[option.id])
				return {
					...tempScore[option.id],
					answer: option.answer,
					percentage: Math.round((tempScore[option.id].score / totalScore) * 100),
				};
			else return { id: option.id, answer: option.answer, score: 0, votedBy: [], percentage: 0.0 };
		});
	};

	let generateResultforQues = (val, users) => {
		let resultArray = [];
		let count = 0;
		Object.entries(val).forEach(([key, value]) => {
			if (key !== "mode") {
				let userId = key;
				let { answer, timestamp } = value;
				let userName = users ? users[key]?.userName || "" : $usersStore[key]?.userName || "";
				let profilePicture = users ? users[key]?.profilePicture || "" : $usersStore[key]?.profilePicture || "";
				resultArray = [...resultArray, { userId, answer, timestamp, profilePicture, userName }];
				resultArray.sort((a, b) => a.timestamp - b.timestamp);
				count++;
			}
		});
		totalScore = count;
		return resultArray;
	};

	$: if (resultofPoll) {
		resultofPoll.sort((a, b) => (a.score < b.score ? 1 : b.score < a.score ? -1 : 0));
	}

	$: {
		if (questionData) {
			if (questionData.created_by_id == user.id) isCreator = true;
			else isCreator = false;
		}
	}

	onMount(async () => {
		listenOnFirebaseKey(dbQuestion(), val => (questionData = val));
		listenOnFirebaseKey(dbAnswersPicked(), async val => {
			voters = Object.keys(val).filter(v => v !== "mode");
			if (questionData?.mode === "poll") {
				resultofPoll = calculateResultforPoll(val);
				if (user.id in val) {
					ownVote = val[user.id];
					resultState = {
						resultofPoll,
						ownVote,
						users,
						totalScore,
						isCreator,
						voters,
					};
					hasVoted = true;
				}
			}
			if (questionData?.mode === "ques") {
				resultofQues = generateResultforQues(val, users);
				if (val[user.id]) {
					ownVote = val[user.id];
					resultState = {
						resultofQues,
						ownVote,
						users,
						totalScore,
						isCreator,
						voters,
					};
					hasVoted = true;
				}
			}
		});
		listenOnFirebaseKey(dbUsers, val => {
			if (val) users = val;
		});
	});
</script>

{#if questionData}
	<div class="container">
		<div class="innerContainer">
			{#if hasVoted}
				<Result {questionData} {resultState} />
			{:else}
				<Vote {questionData} />
			{/if}
		</div>
	</div>
{/if}

<style>
	.container {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
	}
	.innerContainer {
		width: calc(100% - 32px);
		height: auto;
		max-width: 560px;
		max-height: 90%;

		margin-left: auto;
		margin-right: auto;

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;
	}
</style>
